<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left"
      cols="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col
          cols="12"
          class="d-flex align-items-start flex-column-reverse flex-row justify-content-start"
        >
          <div
            v-if="$route.meta.pageTitle"
            class="content-header-title d-flex align-items-center border-0"
          >
            <!-- :class="this.$store.state.app.windowWidth > $themeBreakpoints.md ? '' : 'border-0'" -->
            <!-- <feather-icon
              v-if="$route.meta.icon"
              :icon="$route.meta.icon"
              size="24"
              class="mx-50 text-success"
            /> -->
            <div class="d-flex-center">
              <!-- <feather-icon
                icon="ChevronLeftIcon"
                size="20"
                class="align-text-top text-secondary mr-50"
              /> -->
              <h3 class="my-auto text-dark text-nowrap text-heading-3">
                {{ $t($route.meta.pageTitle) }}
              </h3>
            </div>
          </div>

          <!-- <div class="breadcrumb-wrapper text-body-3">
            <b-breadcrumb class="px-0 pb-50 pb-md-0 flex-nowrap breadcrumb-slash">
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="text-dark hover-overlay"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                <label
                  v-if="item.active"
                  class="text-dark font-weight-bold"
                >
                  {{ $t(item.text) }}
                </label>
                <span
                  v-else
                  class="text-dark hover-overlay"
                >
                  {{ $t(item.text) }}
                </span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div> -->
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right : Dropdown-->
    <!-- <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >

        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </template>

        <b-dropdown-item :to="{ name: 'apps-todo' }">
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Todo</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-chat' }">
          <feather-icon
            icon="MessageSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Chat</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-email' }">
          <feather-icon
            icon="MailIcon"
            size="16"
          />
          <span class="align-middle ml-50">Email</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-calendar' }">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <span class="align-middle ml-50">Calendar</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col> -->
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  // BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { $themeBreakpoints } from '@themeConfig'

export default {
  directives: {
    Ripple,
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BBreadcrumb,
    // eslint-disable-next-line vue/no-unused-components
    BBreadcrumbItem,
    BRow,
    BCol,
    // BDropdown,
    // BDropdownItem,
    // BButton,
  },
  setup() {
    return {
      $themeBreakpoints,
    }
  },
}
</script>
<style>
  .breadcrumb-text-color-custom{
    color: #d0d2d6
  }
</style>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/_variables';

  .hover-overlay:hover{
    color: $info !important;
  }
</style>
