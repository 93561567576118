/* eslint-disable no-unused-vars */
/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'
import appsAndPagesDev from './apps-and-pages-dev'

const STAGING = 'staging'
const DEVELOPMENT = 'development'
const PRODUCTION = 'production'

// Array of sections
function getSidebarContent(appEnv) {
  console.info('VUE_APP_ENV:', appEnv)
  let sidebarContent
  switch (appEnv) {
    case STAGING:
      sidebarContent = [...appsAndPages]
      break
    case PRODUCTION:
      sidebarContent = [...appsAndPages]
      break
    case DEVELOPMENT:
      sidebarContent = [...appsAndPages]
      // sidebarContent = [
      //   ...appsAndPages,
      //   ...appsAndPagesDev,
      //   ...dashboard,
      //   ...uiElements,
      //   ...formAndTable,
      //   ...chartsAndMaps,
      //   ...others,
      // ]
      break
    default:
      sidebarContent = [...appsAndPages]
      // sidebarContent = [
      //   ...appsAndPages,
      //   ...appsAndPagesDev,
      //   ...dashboard,
      //   ...uiElements,
      //   ...formAndTable,
      //   ...chartsAndMaps,
      //   ...others,
      // ]
  }
  return sidebarContent
}

export default getSidebarContent(process.env.VUE_APP_ENV)
