var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visibleForFloor(_vm.item) && _vm.visibleForRole(_vm.item) && !(_vm.item.hideForProduction && _vm.getEnv === 'production') ? _c('li', {
    staticClass: "nav-item",
    class: {
      'active': _vm.isActive,
      'disabled': _vm.item.disabled || !_vm.canViewVerticalNavMenuLink(_vm.item)
    }
  }, [_c('b-link', _vm._b({
    staticClass: "d-flex align-items-center pr-0"
  }, 'b-link', _vm.linkProps, false), [_vm.item.image ? _c('img', {
    staticClass: "mr-50",
    attrs: {
      "src": _vm.item.image,
      "height": "22"
    }
  }) : _c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": _vm.item.icon || 'CircleIcon'
    }
  }), !_vm.isVerticalMenuCollapsed || _vm.isMouseHovered || _vm.$store.state.verticalMenu.isShowText ? _c('span', {
    staticClass: "menu-title text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.t(_vm.item.title)) + " " + _vm._s(_vm.getRouteDescription(_vm.item) === 'F1' ? _vm.mamaShortName : _vm.getRouteDescription(_vm.item)))]) : _vm._e(), _vm.item.tag ? _c('b-badge', {
    staticClass: "mr-1 ml-auto",
    attrs: {
      "pill": "",
      "variant": _vm.item.tagVariant || 'primary'
    }
  }, [_vm._v(" " + _vm._s(_vm.item.tag) + " ")]) : _vm._e()], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }